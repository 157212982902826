import { LoadingIcon, Text } from '@iheartradio/web.companion';
import { Await } from '@remix-run/react';
import { type ReactNode, type SuspenseProps, Suspense } from 'react';

export type DeferredProps<Resolve> = {
  children: ReactNode | ((value: Awaited<Resolve>) => ReactNode);
  errorElement?: ReactNode;
  fallback?: SuspenseProps['fallback'];
  resolve: Resolve;
};

const DefaultErrorElement = () => (
  <Text backgroundColor="$brand-red" color="$brand-white" kind="subtitle-2">
    There was an error
  </Text>
);

export const Deferred = <Resolve,>(props: DeferredProps<Resolve>) => {
  const {
    fallback,
    resolve,
    errorElement = <DefaultErrorElement />,
    children,
  } = props;

  return (
    <Suspense fallback={fallback ?? <LoadingIcon size="48" />}>
      <Await errorElement={errorElement} resolve={resolve}>
        {children}
      </Await>
    </Suspense>
  );
};
